<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>Balance</b-card-title>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-wrap text-center">
          <h1
            class="
                            font-large-2 font-weight-bolder
                            mt-2
                            mb-0
                            text-success
                        "
          >
            $ {{ balance_card.balance }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          sm="6"
          class="d-flex flex-column flex-wrap text-center"
        >
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="BookOpenIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                $ {{ balance_card.open_payment }}
              </h4>
              <b-card-text class="font-small-2 mb-0">
                Open payments
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          sm="6"
          class="d-flex flex-column flex-wrap text-center"
        >
          <b-media no-body>
            <b-media-aside class="mr-1">
              <b-avatar
                size="48"
                variant="light-primary"
              >
                <feather-icon
                  size="24"
                  icon="ClockIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0 text-nowrap">
                $ {{ balance_card.earning_last_24_hours }}
              </h4>
              <b-card-text class="font-small-2 mb-0">
                Last 24 hours
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-footer>
      <b-button
        class="shadow full-width"
        variant="primary"
        @click="$router.push('/payments')"
      >
        <span>Go to payments</span>
        <feather-icon icon="ChevronsRightIcon" />
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
export default {
  // eslint-disable-next-line vue/prop-name-casing
  props: ['balance_card'],
}
</script>

<style>
</style>
