<template>
  <statistic-card-with-area-chart
    v-if="arpdau_card.series"
    icon="UsersIcon"
    :statistic="'$ ' + arpdau_card.arpdau"
    statistic-title="ARPDAU"
    :chart-data="arpdau_card.series"
    :chart-options="chartOptions"
  />
</template>

<script>
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

export default {
  components: {
    StatisticCardWithAreaChart,
  },
  props: [
    'arpdau_card',
  ],
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
          axisTicks: {
            show: false,
          },
          grid: {
            show: false,
          },
          labels: {
            formatter(val) {
              return `$ ${val.toFixed(2)}`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          axisTicks: {
            show: false,
          },
          labels: {
            show: false, // Hide x-axis labels by default
          },
          tooltip: {
            enabled: false,
          },
          categories: this.arpdau_card.categories,
        },
      },
    }
  },
}
</script>
