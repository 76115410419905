<template>
  <statistic-card-with-area-chart
    v-if="monetization_card.series"
    icon="PackageIcon"
    :statistic="monetization_card.monetized_users"
    statistic-title="Monetized users"
    color="warning"
    :chart-data="monetization_card.series"
    :chart-options="chartOptions"
  />
</template>

<script>
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

export default {
  components: {
    StatisticCardWithAreaChart,
  },
  props: [
    'monetization_card',
  ],
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
          axisTicks: {
            show: false,
          },
          grid: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          axisTicks: {
            show: false,
          },
          labels: {
            show: false, // Hide x-axis labels by default
          },
          tooltip: {
            enabled: false,
          },
          categories: this.monetization_card.categories,
        },
      },
    }
  },
}
</script>
