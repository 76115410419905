<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row>
      <b-col>
        <div
          class="
                        d-sm-flex
                        justify-content-between
                        align-items-center
                        card-header
                    "
        >
          <h4 class="card-title mb-50 mb-sm-0">
            Active Users
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-primary"
                  >
                    <feather-icon
                      size="24"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ chart_data.avg_active_users }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Avg Daily Active Users
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
            <div class="d-flex align-items-center ml-75">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="24"
                      icon="TrendingUpIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    <span
                      :class="
                        chart_data.changed_value >= 0
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      <span
                        v-if="chart_data.changed_value >= 0"
                      >+</span>
                      <span>{{ chart_data.changed_percent }} %</span>
                    </span>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    vs last 7 days
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
          </div>
        </div>

        <template v-if="chartHaveData">
          <vue-apex-charts
            v-if="chart_data.series"
            type="bar"
            height="300"
            :options="chartOptions"
            :series="chart_data.series"
          />
        </template>
        <template v-else>
          <div style="width: 100%; height: 100%; position: relative">
            <div style="position: absolute; top: 50%; right: 50%; transform: translate(50%,-50%); font-size: 1.25rem;">
              No data yet
            </div>
          </div>
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: ['chart_data'],
  data() {
    return {
      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          type: 'bar',
          sparkline: { enabled: true },
          toolbar: { show: false },
        },
        states: {
          hover: {
            filter: 'none',
          },
        },
        colors: [
          '#7367f0',
          '#7367f0',
          '#7367f0',
          '#7367f0',
          '#7367f0',
          '#7367f0',
          '#7367f0',
        ],
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
            endingShape: 'rounded', // Deprecated
            // borderRadius: '20px', // Coming Soon
          },
        },
      },
    }
  },
  computed: {
    chartHaveData() {
      if (!this.chart_data || !this.chart_data.series || !this.chart_data.series[0].data) return false
      return this.chart_data.series[0].data.some(value => value.y > 0)
    },
  },
}
</script>

<style>
</style>
