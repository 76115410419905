<template>
  <div
    v-if="isReady"
    id="dashboard-analytics"
  >
    <!-- First Row -->
    <b-row class="match-height">
      <b-col
        lg="3"
        md="6"
      >
        <!-- CARD 1: CONGRATS -->
        <DashboardCongrat
          :first_name="activeUserInfo.first_name"
          :last_30_days_earning="activeUserInfo.earned_last_30_days"
        />
      </b-col>
      <b-col
        lg="3"
        md="6"
      >
        <!-- BALANCE -->
        <DashboardBalance :balance_card="balance_card" />
      </b-col>
      <b-col
        lg="3"
        md="6"
      >
        <!-- ARPDAU -->
        <DashboardARPDAU :arpdau_card="arpdau_card" />
      </b-col>
      <b-col
        lg="3"
        md="6"
      >
        <!-- Monetized users  -->
        <dashboard-monetized-users :monetization_card="monetization_card" />
      </b-col>
    </b-row>

    <!-- Second Row -->
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <!-- Revenue chart -->
        <DashboardRevenue :chart_data="revenueChartData" />
      </b-col>
      <b-col
        lg="6"
        md="12"
      >
        <!-- Avg daily active users -->
        <DashboardDailyActiveUsers :chart_data="activeUsersChartData" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import DashboardMonetizedUsers from '@/views/dashboard/DashboardMonetizedUsers.vue'
import DashboardCongrat from './dashboard/DashboardCongrat.vue'
import DashboardBalance from './dashboard/DashboardBalance.vue'
import DashboardARPDAU from './dashboard/DashboardARPDAU.vue'
import DashboardRevenue from './dashboard/DashboardRevenue.vue'
import DashboardDailyActiveUsers from './dashboard/DashboardDailyActiveUsers.vue'

export default {
  components: {
    DashboardMonetizedUsers,
    DashboardCongrat,
    DashboardBalance,
    DashboardARPDAU,
    DashboardRevenue,
    DashboardDailyActiveUsers,
  },
  data() {
    return {
      balance_card: {
        balance: '0,00',
        open_payment: '0,00',
        earning_last_24_hours: '0,00',
        positive: true,
      },
      arpdau_card: {},
      cpm_card: {},
      monetization_card: {},
      revenueChartData: {},
      activeUsersChartData: {},
      customersData: {},
      isReady: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.auth.AppActiveAdmin
    },
  },
  created() {
    this.showLoading()
    this.$http
      .get('/api/dashboard')
      .then(response => {
        this.hideLoading()
        this.congrat_card = response.data.congrat_card
        this.balance_card = response.data.balance_card
        this.arpdau_card = response.data.arpdau_card
        this.cpm_card = response.data.cpm_card
        this.monetization_card = response.data.monetization_card
        this.revenueChartData = response.data.revenue_card
        this.activeUsersChartData = response.data.active_users_card
        this.customersData = response.data.customer
        this.isReady = true
      })
      .catch(() => {
        this.hideLoading()
      })
  },
}
</script>

<style>
#dashboard-analytics .vx-card__body .decore-left {
  position: absolute;
  left: 0;
}

#dashboard-analytics .vx-card__body .decore-right {
  position: absolute;
  right: 0;
}

#dashboard-analytics #card-congrats .vx-card__body {
  padding: 0.5rem;
  min-height: 16.7rem;
}

#dashboard-analytics #balance-card .vx-card {
  min-height: 16.7rem;
}

#third-group-charts ul {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

#third-group-charts .chart-title {
  margin-left: 1.5rem;
  margin-top: 1rem;
  font-weight: 600;
}

#country_chart_data {
  padding-top: 2rem;
}

#dashboard-analytics .no-data-chart {
  text-indent: 1.5rem;
}
</style>
