<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row>
      <b-col>
        <div
          class="
                        d-sm-flex
                        justify-content-between
                        align-items-center
                        card-header
                    "
        >
          <h4 class="card-title mb-50 mb-sm-0">
            Revenue Report
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="BarChart2Icon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    $ {{ chart_data.last_14_days }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Last 14 days
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
            <div class="d-flex align-items-center ml-75">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="BarChartIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    $ {{ chart_data.previous_14_days }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Previous period
                  </b-card-text>
                </b-media-body>
              </b-media>
            </div>
          </div>
        </div>

        <!-- Chart -->
        <template v-if="chart_data && chart_data.series && chart_data.series.length > 0">
          <vue-apex-charts
            type="line"
            height="300"
            :options="chartOptions"
            :series="chart_data.series"
          />
        </template>
        <template v-else>
          <div>No data yet</div>
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  // eslint-disable-next-line vue/prop-name-casing
  props: ['chart_data'],
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: { show: false },
          dropShadow: {
            enabled: true,
            top: 5,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0, 8],
          width: [4, 2],
        },
        grid: {
          borderColor: '#e7e7e7',
        },
        legend: {
          show: false,
        },
        colors: ['#F97794', '#b8c2cc'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#7367F0', '#b8c2cc'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            style: {
              cssClass: 'text-grey fill-current',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: Array(14)
            .fill()
            .map((x, i) => {
              const theDay = new Date()
              theDay.setDate(theDay.getDate() - (i + 1))
              return theDay.toString().substr(4, 7)
            })
            .reverse(),
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              cssClass: 'text-grey fill-current',
            },
            formatter(val) {
              return window.PRIME_VUE_OBJECT.$options.filters.formatCurrency(
                val,
              )
            },
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    }
  },
}
</script>

<style>
</style>
